/** Component for scroll to top */
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';

function ScrollToTop() {

   /**Initial state */
   const [isVisible, setIsVisible] = useState(false)

   /** Function to show or hide the scroll button based on scroll position */
   const toggleVisibility = () => {
      // let height = 250
      // const windowScroll = document.body.scrollTop || document.documentElement.scrollTop
      // if (windowScroll > height) {
      //    setIsVisible(true)
      // } else {
      //    setIsVisible(false)
      // }
      if (window.scrollY > 500) {
         setIsVisible(true);
      } else {
         setIsVisible(false);
      }
   }

   /** Add event listener when component mounts */
   useEffect(() => {
      window.addEventListener('scroll', toggleVisibility)

      // Remove event listener when component unmounts
      return () => window.removeEventListener('scroll', toggleVisibility)
   }, [])

   return (
      <>
         {isVisible &&
            <motion.button
               onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
               type='button'
               className='btn btn-primary bg-gradient position-fixed  rounded-circle d-flex align-items-center justify-content-center icon-link icon-link-hover icon-hover'
               style={{
                  width: '40px',
                  height: '40px',
                  bottom: '50px',
                  right: '40px',
               }}
               initial={{ opacity: 0, scale: 0.5 }}
               animate={{ opacity: 1, scale: 1 }}
               transition={{ duration: 0.5 }}
            >
               <i className="bi bi-arrow-up" />
            </motion.button>
         }
      </>
   )
}

export default ScrollToTop