import { motion } from 'framer-motion'
import { Image } from 'react-bootstrap'
import { Typewriter } from 'react-simple-typewriter'
import { AppWrap } from '../../Wrapper'
import { images } from '../../Constants'
import { useTheme } from '../../Context/ThemeContext';
import Images from 'Constants/Images'

const scaleVariants = {
	whileInView: {
		scale: [0, 1],
		opacity: [0, 1],
		transition: {
			duration: 1,
			ease: 'easeInOut'
		}
	}
}
function Header() {

	const { theme } = useTheme();

	return (
		<div id='home' className={`app__header app__flex ${theme === 'light' ? 'home-bg' : ''}`}>
			<motion.div
				whileInView={{ x: [-100, 0], opacity: [0, 1] }
				}
				transition={{ duration: 0.5 }}
				className='app__header-info'
			>
				<div className='app__header-badge'>
					<div className='badge-cmp app__flex'>
						<Image
							fluid
							src={Images.wave}
							alt="hello-img"
							width={50}
							height={50}
							className='object-fit-cover'
							style={{ transform: 'rotateZ(-30deg)' }}
						/>
						<div className='ms-20'>
							<p className='mb-0 fw-semibold fs-17'>Hello, I am</p>
							<h1 className='text-capitalize mb-0'>
								<Typewriter
									words={['Aryan', 'Frontend developer', 'Backend developer', 'Freelancer', 'Digital marketer',]}
									loop={0}
									cursor
									cursorStyle='|'
									cursorColor={theme === 'light' ? '#000' : '#fff'}
									typeSpeed={70}
									deleteSpeed={50}
									delaySpeed={1000}
								/>
							</h1>
						</div>
					</div>
					{/* <div className='tag-cmp app__flex gap-5'>
						<p className='mb-0 fs-12'>Web Developer</p>
						<p className='mb-0 fs-12'>Freelancer</p>
					</div> */}
				</div>
			</motion.div>
			<motion.div
				whileInView={{ opacity: [0, 1] }}
				transition={{ duration: 0.5, delayChildren: 0.5 }}
				className='app__header-img'
			>
				<Image
					fluid
					src={images.profile}
					alt="profile_bg"
					width={700}
					height={700}
				/>
				<motion.img
					whileInView={{ scale: [0, 1] }}
					transition={{ duration: 1, ease: 'easeInOut' }}
					src={images.circle}
					fetchpriority="high"
					alt="profile_circle"
					className="overlay_circle img-fluid"
					width={700}
					height={700}
				/>
			</motion.div>
			<motion.div
				variants={scaleVariants}
				whileInView={scaleVariants.whileInView}
				className='app__header-circles'
			>
				{[images.react, images.redux, images.sass, images.node].map((circle, index) => (
					<div className='circle-cmp app__flex' key={`circle-${index}`}>
						<Image
							fluid
							loading='lazy'
							src={circle}
							alt="profile_bg"
							width={200}
							height={200}
						/>
					</div>
				))}
			</motion.div>
		</div >
	)
}

export default AppWrap(Header, 'home', 'home-bg')  