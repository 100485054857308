import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Image } from 'react-bootstrap'
import { client, urlFor } from '../../client'
import { AppWrap, MotionWrap } from '../../Wrapper'

function About() {

	/**Initial state */
	const [abouts, setAbouts] = useState([])

	/**Lifecycle hook */
	useEffect(() => {

		const fetchAboutData = async () => {
			try {
				const query = '*[_type == "abouts"]';
				const result = await client.fetch(query)
				setAbouts(result)
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		// Call the fetchData function
		fetchAboutData();
	}, [])

	return (
		<>
			<h2 className='head-text'>I know <span>Good Development</span><br />means <span>Good Business</span></h2>
			<div className='app__profiles'>
				{abouts.map((about, index) => (
					<motion.div
						whileInView={{ opacity: 1 }}
						whileHover={{ scale: 1.1 }}
						transition={{ duration: 0.5, type: 'tween' }}
						className="app__profile-item "
						key={about.title + index}
					>
						<div className='app__profile-img d-flex align-items-center justify-content-center flex-grow-1'>
							<Image
								fluid
								loading="lazy"
								src={urlFor(about.imgUrl)}
								alt={about.title}
								className='w-100 h-100 object-fit-cover rounded'
								width={300}
								height={300}
							/>
						</div>
						<div>
							<h2 className='bold-text' style={{ marginTop: '20px' }}>{about.title}</h2>
							<p className='p-text' style={{ marginTop: '10px' }}>{about.description}</p>
						</div>
					</motion.div>
				))}
			</div>
		</>
	)
}

export default AppWrap(MotionWrap(About, 'app__about'), 'about', 'bg-white')