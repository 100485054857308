import { createClient } from '@sanity/client'
import imageUrlBuilder from "@sanity/image-url";

// Create a Sanity client with the specified configuration
export const client = createClient({
	projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
	dataset: 'production',
	useCdn: true, // set to `false` to bypass the edge cache
	apiVersion: '2024-03-13', // use current date (YYYY-MM-DD) to target the latest API version
	token: process.env.REACT_APP_SANITY_TOKEN //
})

//Quickly generate image urls from Sanity image records
const builder = imageUrlBuilder(client)

// Function to generate image URLs using the imageUrlBuilder
export const urlFor = (source) => builder.image(source)
