import { useState } from 'react'
import { Button, Dropdown, Image, Nav, Offcanvas } from 'react-bootstrap'
import { motion } from 'framer-motion';
import { useTheme } from '../../Context/ThemeContext';
import { images } from '../../Constants'
import Tooltip from '../Tooltip';
import { socialMediaData } from '../../Services/helpers'

const getIcon = (theme) => {
	switch (theme) {
		case 'light':
			return <i className="bi bi-brightness-high-fill"></i>;
		case 'dark':
			return <i className="bi bi-moon-stars-fill"></i>;
		case 'auto':
			return <i className="bi bi-circle-half"></i>;
		default:
			return null;
	}
};

const headerMenu = [
	{
		link: 'home',
		icon: 'bi-house-fill'
	},
	{
		link: 'about',
		icon: 'bi-person-circle'
	},
	{
		link: 'work',
		icon: 'bi-handbag-fill'
	},
	{
		link: 'skills',
		icon: 'bi-pie-chart-fill'
	},
	{
		link: 'certification',
		icon: 'bi bi-images'
	},
	{
		link: 'contact',
		icon: 'bi-chat-square-text-fill'
	}
]
function Menubar() {

	/**Initial state */
	const [show, setShow] = useState(false);
	const [activeTab, setActiveTab] = useState("#home");


	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	/**Theme context */
	const { theme, toggleTheme } = useTheme();

	/**Handle theme change */
	const handleThemeChange = (newTheme) => {
		if (newTheme === 'auto') {
			const preferredTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
			toggleTheme(preferredTheme);
		} else {
			toggleTheme(newTheme);
		}
	};

	return (
		<>
			<nav className='app__navbar'>
				<motion.div className='app__navbar-logo'
					initial={{ opacity: 0, scale: 0.5 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.5 }}
				>
					<Image fluid src={images.devLogo} alt="logo" className='object-fit-cover w-100 h-100' width={500} height={500} />
				</motion.div>
				<ul className='app__navbar-links d-none d-lg-block list-unstyled mb-0'>
					{headerMenu?.map((item) => (
						<li key={`link-${item.link}`} className='app__flex p-text'>
							<a href={`#${item.link}`} className='icon-link icon-link-hover icon-hover'>
								<i className={`bi ${item.icon} lh-1`} />{item.link}</a>
						</li>
					))}
				</ul>
				<div className="d-flex gap-15">
					<Dropdown>
						<Tooltip content="Toggle theme" placement='bottom'>
							<Dropdown.Toggle
								variant="primary"
								style={{ width: '40px', height: '40px' }}
								size="sm"
								aria-label="Theme toggler"
								className="custom-dropdown d-flex align-items-center justify-content-center rounded-circle custom-dropdown__hide-caret"
							>
								{getIcon(theme)}
							</Dropdown.Toggle>
						</Tooltip>
						<Dropdown.Menu className='border-light-subtle'>
							<Dropdown.Item
								active={theme === 'light' ? 'active' : ''}
								onClick={() => handleThemeChange('light')}
								className='fw-semibold fs-12 d-flex gap-5'
							>
								{getIcon('light')} Light
							</Dropdown.Item>
							<Dropdown.Item
								active={theme === 'dark' ? 'active' : ''}
								onClick={() => handleThemeChange('dark')}
								className='fw-semibold fs-12 d-flex gap-5'
							>
								{getIcon('dark')} Dark
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Button
						className="rounded-circle bi bi-list d-lg-none d-flex align-items-center justify-content-center lh-1 fs-20"
						onClick={() => handleShow()}
						style={{ height: '40px', width: '40px' }}
						aria-label="Side menu toggler"
					/>
				</div>
			</nav>
			<Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: '280px' }}>
				<Offcanvas.Body className='p-0'>
					<div className="d-flex flex-column h-100 p-15">
						<motion.a
							href="#home"
							onClick={() => handleClose()}
							className="mb-0 mb-md-0 me-md-auto text-decoration-none w-100"
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ duration: 0.5 }}
						>
							<div
								style={{
									width: "100%",
									height: '100px'
								}}
								className={theme === 'dark' ? 'bg-primary bg-gradient rounded opacity-75' : ''}
							>
								<Image
									fluid
									src={images.devLogo}
									alt="logo"
									className='object-fit-cover w-100 h-100'
									width={300}
									height={300}
								/>
							</div>
						</motion.a>
						<hr />
						<Nav
							variant="pills"
							className='flex-column mb-auto sidebar'
							defaultActiveKey="/home"
							activeKey={activeTab}
							onSelect={(eventKey) => setActiveTab(eventKey)}
						>
							{headerMenu?.map((item) => (
								<Nav.Item key={item.link}>
									<Nav.Link
										href={`#${item.link}`}
										className={`text-decoration-none text-capitalize lh-1 ${theme === 'dark' ? 'text-dark' : 'text-body-secondary'}`}
										onClick={() => handleClose()}
									>
										<i className={`me-5 bi ${item.icon}`} />{item.link}
									</Nav.Link>
								</Nav.Item>
							))}
						</Nav>
						<hr />
						<motion.div
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ duration: 0.5 }}
							className='d-inline-flex align-items-center justify-content-center gap-10 lh-1'
						>
							{socialMediaData?.map((item) => (
								<Tooltip key={item.icon} content={item.label}>
									<a
										key={item.icon}
										href={item.url}
										target='_blank'
										rel="noreferrer"
										className='d-flex align-items-center justify-content-center border bg-gradient border-light-subtle rounded-circle'
										style={{
											width: '40px',
											height: '40px',
											color: item.color ? item.color : '#fff',
											backgroundColor: item.background ? item.background : '#000'
										}}
									>
										<i className={`${item.icon} fs-19`}></i>
									</a>
								</Tooltip>
							))}
						</motion.div>
					</div>
				</Offcanvas.Body>
			</Offcanvas >
		</>
	)
}

export default Menubar