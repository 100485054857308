import email from '../Assets/images/email.png';
import mobile from '../Assets/images/mobile.png';
import css from '../Assets/images/css.png';
import git from '../Assets/images/git.png';
import html from '../Assets/images/html.png';
import javascript from '../Assets/images/javascript.png';
import node from '../Assets/images/node.webp';
import react from '../Assets/images/react.png';
import redux from '../Assets/images/redux.webp';
import sass from '../Assets/images/sass.webp';
import typescript from '../Assets/images/typescript.png';

import profile from '../Assets/images/profile.webp';
import circle from '../Assets/images/circle.svg';
import devLogo from '../Assets/images/devaryan-logo.webp';

import asus from '../Assets/images/asus.png';
import bolt from '../Assets/images/bolt.png';
import skype from '../Assets/images/skype.png';
import spotify from '../Assets/images/spotify.png';
import wave from '../Assets/images/wave.webp';

export default {
	email,
	mobile,
	css,
	git,
	html,
	javascript,
	node,
	react,
	redux,
	sass,
	typescript,
	profile,
	circle,
	asus,
	bolt,
	skype,
	spotify,
	devLogo,
	wave
};