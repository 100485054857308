import { Fragment, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Image, Card } from 'react-bootstrap'
import { AppWrap, MotionWrap } from '../../Wrapper'
import { client, urlFor } from '../../client'
function Skills() {

	/**Initial state */
	const [experiences, setExperiences] = useState([])
	const [skills, setSkills] = useState([])

	/**Lifecycle hook */
	useEffect(() => {
		const fetchSkills = async () => {
			try {
				const experiencesQuery = '*[_type == "experiences"]';
				const skillsQuery = '*[_type == "skills"]';
				const experiencesResult = await client.fetch(experiencesQuery)
				const skillsResult = await client.fetch(skillsQuery)
				setExperiences(experiencesResult)
				setSkills(skillsResult)
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		// Call the fetchData function
		fetchSkills();
	}, [])

	return (
		<>
			<h2 className='head-text'>Skills & Experience</h2>
			<div className='app__skills-container gap-20'>
				<motion.div className='app__skills-list gap-20 flex-shrink-0 '>
					{skills?.map((skill) => (
						<motion.div
							whileInView={{ opacity: [0, 1] }}
							transition={{ duration: 0.5 }}
							className="app__skills-item app__flex gap-5"
							key={skill.name}
						>
							<div className='app__flex' style={{ backgroundColor: skill.bgColor }}>
								<Image
									fluid
									loading="lazy"
									src={urlFor(skill.icon)}
									alt={skill.name}
									width={100}
									height={100}
								/>
							</div>
							<p className='fs-12 fw-semibold text-body-tertiary m-0'>{skill.name}</p>
						</motion.div>
					))}
				</motion.div>
				<div className="app__skills-exp flex-grow-1 ">
					{/* <!-- Timeline --> */}
					<section className="bsb-timeline-4 w-100">
						<div className="container">
							<ul className="timeline">
								{experiences?.map((experience, index) => (
									<li key={experience.year} className={`timeline-item ${index % 2 === 0 ? 'right' : 'left'}`}>
										<div className="timeline-body">
											<div className="timeline-meta">
												<div className="d-inline-flex flex-column px-10 py-5 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-2 text-md-end">
													<span className="fw-bold lh-1">{experience.year}</span>
													{/* <span>(Internship)</span> */}
												</div>
											</div>
											{experience?.works?.map((work) => (
												<div key={work.name} className="timeline-content timeline-indicator">
													<Card className="border-0 shadow">
														<Card.Body className="">
															<Card.Title className="mb-10">{work.name}</Card.Title>
															<Card.Subtitle className="text-body-tertiary mb-5 fs-14">{work.company}</Card.Subtitle>
															<Card.Text className="m-0 text-body-secondary">{work.desc}</Card.Text>
														</Card.Body>
													</Card>
												</div>
											))}
										</div>
									</li>
								))}
							</ul>
						</div>
					</section>
				</div>
			</div>
		</>
	)
}

export default AppWrap(MotionWrap(Skills, 'app__skills'), 'skills', 'bg-white')