import React, { useEffect, useState } from 'react'
import { Image, Container, Carousel } from 'react-bootstrap';
import { AppWrap, MotionWrap } from '../../Wrapper'
import { client, urlFor } from '../../client'
function Certification() {

	/**initial state */
	const [certificates, setCertificates] = useState([])

	/**Lifecycle hook */
	useEffect(() => {
		const fetchCertificates = async () => {
			try {
				const certificatesQuery = '*[_type == "certificates"]';
				const certificatesResult = await client.fetch(certificatesQuery)
				setCertificates(certificatesResult)
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		// Call the fetchData function
		fetchCertificates();
	}, [])

	return (
		<>
			<h2 className='head-text'>certification</h2>
			<Container className='mt-45'>
				<Carousel data-bs-theme="dark" className='rounded'>
					{certificates?.map((certificate) => (
						<Carousel.Item key={certificate._id}>
							<Image
								fluid
								loading='lazy'
								src={urlFor(certificate.imgUrl)}
								alt="certificate-img"
								className='w-100 object-fit-cover '
								width={500}
								height={500}
							/>
						</Carousel.Item>
					))}
				</Carousel>
			</Container>
		</>
	)
}
export default AppWrap(MotionWrap(Certification, 'app__certificates'), 'certification', 'primarybg')
