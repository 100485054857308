import { useRef, useState } from 'react';
import { Button, Form, Image, Col } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { motion, useInView } from 'framer-motion'
import { toast } from "react-toastify";
import { Player } from '@lottiefiles/react-lottie-player';
import { images } from '../../Constants';
import { AppWrap, MotionWrap } from '../../Wrapper';
// import { client } from '../../client';
import { socialMediaData } from 'Services/helpers';
import { Tooltip } from 'Components';

const Footer = () => {

	/**Initial State*/
	// const [formData, setFormData] = useState({ username: '', email: '', message: '' });
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	const formRef = useRef();
	const ref = useRef();

	const isInView = useInView(ref, { margin: '-100px' })

	/**Destructure formData state */
	// const { username, email, message } = formData;

	/**Handle input change */
	// const handleChangeInput = (e) => {
	// 	const { name, value } = e.target;
	// 	setFormData({ ...formData, [name]: value });
	// };

	/**Handle form submit */
	// const handleSubmit = async () => {
	// 	try {
	// 		setLoading(true); // Set loading state to true when the form is being submitted
	// 		await client.create({
	// 			_type: 'contact', // Replace with your schema type name
	// 			name: username,
	// 			email,
	// 			message,
	// 		});
	// 		// Once the form submission is successful, update states accordingly
	// 		setIsFormSubmitted(true);
	// 		setLoading(false); // Set loading state back to false after submission
	// 	} catch (error) {
	// 		console.error('Error submitting form:', error);
	// 		alert('There was an error submitting your form. Please try again later.');
	// 		setLoading(false); // Set loading state back to false in case of error
	// 	}
	// };


	/**Send email function */
	const sendEmail = (e) => {
		e.preventDefault();
		setLoading(true);
		emailjs
			.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, formRef.current, {
				publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
			})
			.then(
				() => {
					setLoading(false);
					setIsFormSubmitted(true);
					toast('Message sent successfully');
					// Reset form fields
					formRef.current.reset();
				},
				(error) => {
					setLoading(false);
					setIsFormSubmitted(false)
					toast.error('Sending message failed. Please try again.');
					console.log('FAILED...', error || error?.text || error.message);
				},
			);
	};

	return (
		<>
			<h2 className="head-text">Take a coffee & chat with me</h2>
			<div className="app__footer-cards gap-20">
				<a
					href="mailto:devaryan.online@gmail.com"
					className="app__footer-card p-15"
				>
					<Image fluid loading="lazy" src={images.email} alt="email" width={100} height={100} />
					<span className='ps-10 text-body-secondary fs-14 flex-grow-1'>devaryan.online@gmail.com</span>
				</a>
				<a
					href="tel:+917009945848"
					className="app__footer-card p-15"
				>
					<Image fluid loading="lazy" src={images.mobile} alt="phone" width={100} height={100} />
					<span className='ps-10 text-body-secondary fs-14 flex-grow-1'>+917009945848</span>
				</a>
			</div>
			<div className='d-md-none d-flex flex-wrap align-item-center justify-content-center gap-20'>
				{socialMediaData?.map((item) => (
					<Tooltip key={item.icon} content={item.label} placement='top'>
						<a
							href={item.url}
							target='_blank'
							rel="noreferrer"
							aria-label={item.label}
							className='d-flex align-items-center justify-content-center border bg-gradient rounded-circle'
							style={{
								width: "40px",
								height: "40px",
								color: item.color ? item.color : '#fff',
								backgroundColor: item.background ? item.background : '#000'
							}}
						>
							<i className={`${item.icon} lh-1`}></i>
						</a>
					</Tooltip>
				))}
			</div>
			{!isFormSubmitted ? (
				<motion.div ref={ref} className='formContainer app__footer-form app__flex position-relative'>
					<motion.div
						className='phoneSvg position-absolute top-0 bottom-0 start-0 end-0'
						initial={{ opacity: 1 }}
						whileInView={{ opacity: 0 }}
						transition={{ delay: 3, duration: 1 }}
					>
						<svg
							fill="none"
							width="450px"
							height="450px"
							viewBox="0 0 32.666 32.666"
							xmlSpace="preserve"
							className='responsive-svg m-auto mt-25 d-block'
						>
							<g>
								<motion.path
									strokeWidth={0.2}
									fill='none'
									initial={{ pathLength: 0 }}
									animate={isInView && { pathLength: 1 }}
									transition={{ duration: 3 }}
									d="M28.189,16.504h-1.666c0-5.437-4.422-9.858-9.856-9.858l-0.001-1.664C23.021,4.979,28.189,10.149,28.189,16.504z
               			M16.666,7.856L16.665,9.52c3.853,0,6.983,3.133,6.981,6.983l1.666-0.001C25.312,11.735,21.436,7.856,16.666,7.856z M16.333,0
               			C7.326,0,0,7.326,0,16.334c0,9.006,7.326,16.332,16.333,16.332c0.557,0,1.007-0.45,1.007-1.006c0-0.559-0.45-1.01-1.007-1.01
               			c-7.896,0-14.318-6.424-14.318-14.316c0-7.896,6.422-14.319,14.318-14.319c7.896,0,14.317,6.424,14.317,14.319
               			c0,3.299-1.756,6.568-4.269,7.954c-0.913,0.502-1.903,0.751-2.959,0.761c0.634-0.377,1.183-0.887,1.591-1.529
               			c0.08-0.121,0.186-0.228,0.238-0.359c0.328-0.789,0.357-1.684,0.555-2.518c0.243-1.064-4.658-3.143-5.084-1.814
               			c-0.154,0.492-0.39,2.048-0.699,2.458c-0.275,0.366-0.953,0.192-1.377-0.168c-1.117-0.952-2.364-2.351-3.458-3.457l0.002-0.001
               			c-0.028-0.029-0.062-0.061-0.092-0.092c-0.031-0.029-0.062-0.062-0.093-0.092v0.002c-1.106-1.096-2.506-2.34-3.457-3.459
               			c-0.36-0.424-0.534-1.102-0.168-1.377c0.41-0.311,1.966-0.543,2.458-0.699c1.326-0.424-0.75-5.328-1.816-5.084
              				c-0.832,0.195-1.727,0.227-2.516,0.553c-0.134,0.057-0.238,0.16-0.359,0.24c-2.799,1.774-3.16,6.082-0.428,9.292
               			c1.041,1.228,2.127,2.416,3.245,3.576l-0.006,0.004c0.031,0.031,0.063,0.06,0.095,0.09c0.03,0.031,0.059,0.062,0.088,0.095
               			l0.006-0.006c1.16,1.118,2.535,2.765,4.769,4.255c4.703,3.141,8.312,2.264,10.438,1.098c3.67-2.021,5.312-6.338,5.312-9.719
               			C32.666,7.326,25.339,0,16.333,0z"/>
							</g>
						</svg>
					</motion.div>
					<motion.form
						ref={formRef}
						onSubmit={sendEmail}
						className="app__footer-form app__flex z-1"
						initial={{ opacity: 0 }}
						whileInView={{ opacity: 1 }}
						transition={{ delay: 4, duration: 1 }}
					>
						<Form.Group className="app__flex w-100 mb-20">
							<Form.Control
								type="text"
								placeholder="Your name"
								name="username"
								required
							/>
						</Form.Group>
						<Form.Group className="app__flex w-100 mb-20">
							<Form.Control
								type="email"
								required
								placeholder="Your email"
								name="email"
							/>
						</Form.Group>
						<Form.Group className="app__flex w-100 mb-20">
							<Form.Control
								as="textarea"
								placeholder="Your message..."
								name="message"
								required
							/>
						</Form.Group>
						<Button
							variant="primary"
							type='submit'
							className='bg-gradient'
						>
							{!loading ? 'Send Message' : 'Sending...'}
						</Button>
					</motion.form>
					{/* <Form ref={formRef} onSubmit={sendEmail} className="app__footer-form app__flex">
						<div className="app__flex">
							<Form.Control
								type="text"
								placeholder="Your name"
								name="username"
								required
							/>
						</div>
						<div className="app__flex">
							<Form.Control
								// className="p-text"
								type="email"
								required
								placeholder="Your email"
								name="email"
							/>
						</div>
						<div>
							<Form.Control
								as="textarea"
								// className="p-text"
								placeholder="Your message..."
								name="message"
								required
							/>
						</div>
						<Button
							variant="primary"
							type='submit'
						>
							{!loading ? 'Send Message' : 'Sending...'}
						</Button>
					</Form> */}
				</motion.div>
			) : (
				<Col lg={8} xxl={6} className='mx-auto'>
					<h3 className="head-text">
						<Player
							autoplay
							loop
							src="https://lottie.host/0e29c6a0-1d3e-42c5-8a1c-89d686e7d112/ZwYVcrYHZY.json"
							style={{ height: '300px', width: '300px' }}
						/>
						Thank you for getting in touch!
					</h3>
					<p className='text-center'>Your message has been successfully sent. I appreciate you taking the time to get in touch. I'll review your message and get back to you shortly. In the meantime, feel free to explore more of my work or connect with me on social media. Looking forward to chatting with you soon!</p>
				</Col>
			)}
		</>
	);
};

export default AppWrap(
	MotionWrap(Footer, 'app__footer'),
	'contact',
	'primarybg',
);
