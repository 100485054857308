import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Image, Row, Col, Card, Badge, Button } from 'react-bootstrap'
import { Player } from '@lottiefiles/react-lottie-player';
import { AppWrap, MotionWrap } from '../../Wrapper'
import { client, urlFor } from '../../client'
function Work() {

	/**Initial state */
	const [activeFilter, setActiveFilter] = useState('All')
	const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })
	const [works, setWorks] = useState([])
	const [filterWork, setFilterWork] = useState([])
	const [isHovered, setIsHovered] = useState(false);

	/**Lifecycle hook */
	useEffect(() => {
		const fetchWorkData = async () => {
			try {
				const query = '*[_type == "works"]';
				const result = await client.fetch(query)
				setWorks(result)
				setFilterWork(result)
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		// Call the fetchData function
		fetchWorkData();
	}, [])

	/**Function to handle the filter */
	const handleWorkFilter = (item) => {
		setActiveFilter(item);
		setAnimateCard([{ y: 100, opacity: 0 }])

		setTimeout(() => {
			setAnimateCard([{ y: 0, opacity: 1 }])
			if (item === 'All') {
				setFilterWork(works)
			} else {
				setFilterWork(works.filter((work) => work?.tags.includes(item)));
			}
		}, 500)

	}

	return (
		<>
			<h2 className='head-text'>My Creative <span>Projects</span></h2>
			<div className='app__work-filter'>
				{['All', 'React Js', 'Express', 'Next Js', 'Firebase', 'MongoDB'].map((item, index) => (
					<div
						key={index}
						onClick={() => handleWorkFilter(item)}
						className={`bg-gradient app__work-filter-item app-flex p-text ${activeFilter === item ? 'item-active' : ''}`}
					>
						{item}
					</div>
				))}
			</div>
			<motion.div
				animate={animateCard}
				transition={{ duration: 0.5, delayChildren: 0.5 }}
				className="app__work-portfolio"
			>
				{filterWork?.length > 0
					?
					<>
						<Row className='gy-20 w-100 app__work-item'>
							{filterWork.map((work, index) => (
								// <div key={index} className='app__work-item app__flex'>
								// 	<div className='app__work-img app__flex'>
								// 		<Image fluid src={urlFor(work.imgUrl)} alt={work.name} />
								// 		<motion.div
								// 			whileHover={{ opacity: [0, 1] }}
								// 			transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
								// 			className='app__work-hover app__flex gap-10'
								// 		>
								// 			<a href={work.projectLink} target="_blank" rel="noreferrer">
								// 				<motion.div
								// 					whileInView={{ scale: [0, 1] }}
								// 					whileHover={{ scale: [1, 0.9] }}
								// 					transition={{ duration: 0.25 }}
								// 					className='app__flex'
								// 				>
								// 					<i className="bi bi-eye-fill lh-1"></i>
								// 				</motion.div>
								// 			</a>
								// 			<a href={work.codeLink} target="_blank" rel="noreferrer">
								// 				<motion.div
								// 					whileInView={{ scale: [0, 1] }}
								// 					whileHover={{ scale: [1, 0.9] }}
								// 					transition={{ duration: 0.25 }}
								// 					className='app__flex'
								// 				>
								// 					<i className="bi bi-github lh-1"></i>
								// 				</motion.div>
								// 			</a>
								// 		</motion.div>
								// 	</div>
								// 	<div className='app__work-content app__flex'>
								// 		<h4 className='bold-text mb-0'>{work.title}</h4>
								// 		<div className='app__work-tag app__flex'>
								// 			<p className='p-text mb-0'>{work.tags[0]}</p>
								// 		</div>
								// 		<p className='p-text mt-10 mb-0'>{work.description}</p>
								// 	</div>
								// </div>
								<Col key={index} sm={12}>
									<Card className=''>
										<Card.Body>
											<Row className='g-20'>
												<Col lg={6}>
													<div className='card__img rounded' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
														<div className='cursor-pointer'>
															<Image
																fluid
																loading="lazy"
																src={urlFor(work.imgUrl)}
																alt={work.title}
																className={`w-100 h-100 object-fit-cover ${isHovered ? 'hovered' : ''}`}
																width={800}
																height={800}
															// style={{ transform: isHovered ? 'translateY(-59.375%)' : 'translateY(0)' }}
															/>
														</div>
													</div>
												</Col>
												<Col lg={6}>
													<Card className='bg-transparent'>
														<Card.Body className='p-0 d-flex flex-column gap-10'>
															<div className='d-block d-md-flex align-items-start gap-10'>
																<Card.Title>{work.title}</Card.Title>
																<div className='d-flex flex-wrap gap-5'>
																	{work?.tags.filter(tag => tag !== 'All').map((tag, index) => (
																		<Badge key={index} className='bg-primary-subtle text-primary-emphasis'>{tag}</Badge>
																	))}
																</div>
															</div>
															<Card.Text className='text-body-secondary'>{work.description}</Card.Text>
															<div className='d-flex flex-wrap gap-20 '>
																<Button href={work.projectLink} target='_blank' variant="dark" type='button' className='bg-gradient w-100-sm'>
																	<i className="bi bi-eye-fill lh-1 me-5" /> View
																</Button>
																<Button href={work.codeLink} target='_blank' variant="primary" type='button' className='bg-gradient w-100-sm'>
																	<i className="bi bi-github lh-1 me-5" /> Source
																</Button>
															</div>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>
					</>
					:
					<div className='app__work-item'>
						<Player
							autoplay
							loop
							src="https://lottie.host/71a7c53d-34a4-4d75-9fb9-15921ac7dd5d/KVWiyDl2RJ.json"
							style={{ height: '200px', width: '200px' }}
						/>
						<p className='mb-0 text-center fw-bold'>There is no  project available with this tag.</p>
					</div>
				}
			</motion.div >
		</>
	)
}

export default AppWrap(MotionWrap(Work, 'app__works'), 'work', 'primarybg')