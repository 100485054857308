import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Image } from 'react-bootstrap'
import { AppWrap, MotionWrap } from '../../Wrapper';
import { urlFor, client } from '../../client';

const Testimonial = () => {

	/**Initial state */
	const [currentIndex, setCurrentIndex] = useState(0);
	const [testimonials, setTestimonials] = useState([]);
	const [brands, setBrands] = useState([]);

	const handleClick = (index) => {
		setCurrentIndex(index);
	};

	/**Lifecycle hook */
	useEffect(() => {
		const query = '*[_type == "testimonials"]';
		const brandsQuery = '*[_type == "brands"]';

		client.fetch(query).then((data) => {
			setTestimonials(data);
		});

		client.fetch(brandsQuery).then((data) => {
			setBrands(data);
		});
	}, []);

	return (
		<>
			{testimonials?.length > 0 && (
				<>
					<div className="app__testimonial-item app__flex gap-25">
						<Image
							fluid
							loading="lazy"
							src={urlFor(testimonials[currentIndex].imageurl)}
							alt={testimonials[currentIndex].name}
							width={100}
							height={100}
						/>
						<div className="app__testimonial-content">
							<p className="p-text text-body-secondary">{testimonials[currentIndex].feedback}</p>
							<div>
								<span className="bold-text">{testimonials[currentIndex].name}</span>
								<small className="fs-12 d-block mt-5 text-body-secondary">{testimonials[currentIndex].company}</small>
							</div>
						</div>
					</div>
					<div className="app__testimonial-btns app__flex">
						<div className="app__flex bg-body" onClick={() => handleClick(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1)}>
							<i className="bi bi-chevron-left lh-1"></i>
						</div>
						<div className="app__flex bg-body" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1)}>
							<i className="bi bi-chevron-right lh-1"></i>
						</div>
					</div>
				</>
			)}
			{/* Brand images */}
			<div className="app__testimonial-brands app__flex">
				{brands?.map((brand) => (
					<motion.div
						whileInView={{ opacity: [0, 1] }}
						transition={{ duration: 0.5, type: 'tween' }}
						key={brand._id}
					>
						<Image
							fluid
							loading="lazy"
							src={urlFor(brand.imgUrl)}
							alt={brand.name}
							width={150}
							height={150}
						/>
					</motion.div>
				))}
			</div>
		</>
	);
};

export default AppWrap(
	MotionWrap(Testimonial, 'app__testimonial'),
	'testimonials',
	'bg-white'
);
