import { ToastContainer, Flip } from "react-toastify";
import Menubar from './Components/Menubar';
import { useTheme } from './Context/ThemeContext';
import { About, Header, Footer, Skills, Testimonial, Work } from './Container'
import Certification from "./Container/Certification";
import ScrollToTop from "Components/ScrollToTop";
function App() {

	const { theme } = useTheme();

	return (
		<>
			<div className='app'>
				<Menubar />
				<Header />
				<About />
				<Work />
				<Skills />
				<Certification />
				<Testimonial />
				<ScrollToTop />
				<Footer />
			</div>
			<ToastContainer
				icon={false}
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				rtl={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover
				transition={Flip}
				theme={theme}
			/>
		</>
	);
}

export default App;
