export function getGreeting() {
	let myDate = new Date();
	let hrs = myDate.getHours();
	let greeting = "Welcome!";
	if (hrs < 12) {
		greeting = "Good Morning!";
	} else if (hrs >= 12 && hrs < 16) {
		greeting = "Good Afternoon!";
	} else if (hrs >= 16 && hrs <= 24) {
		greeting = "Good Evening!";
	}
	return greeting;
}

export const socialMediaData = [
	{
		label: 'Github',
		icon: 'bi-github',
		url: 'https://github.com/mearyansingh',
	},
	{
		label: 'LinkedIn',
		icon: 'bi-linkedin',
		url: 'https://www.linkedin.com/in/iamaryansingh/',
	},
	{
		label: 'Twitter',
		icon: 'bi-twitter-x',
		url: 'https://twitter.com/aryansingh_1810',
	},
	{
		label: 'Instagram',
		icon: 'bi-instagram',
		url: 'https://www.instagram.com/devaryan.online',
		background: '#E4405F'
	},
	{
		label: 'Facebook',
		icon: 'bi-facebook',
		url: 'https://www.facebook.com/iaryansingh.me/',
		background: '#0866FF'
	},
]