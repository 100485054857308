import { socialMediaData } from '../../Services/helpers'
import { Tooltip } from '../../Components';

const SocialMedia = () => {

	return (
		<div className='app__social'>
			{socialMediaData?.map((item) => (
				<Tooltip key={item.icon} content={item.label} placement='right'>
					<a href={item.url} target='_blank' rel="noreferrer" aria-label={item.label}>
						<i className={`${item.icon} lh-1`}></i>
					</a>
				</Tooltip>
			))}
		</div>
	)
}

export default SocialMedia