import { NavigationDots, SocialMedia } from '../Components'
import { useTheme } from '../Context/ThemeContext';

const AppWrap = (Component, idName, classNames) => function HOC() {

	const { theme } = useTheme();

	const sectionClass = (classNames === 'home-bg' && theme === 'dark') ? '' : classNames

	return (
		<div id={idName} className={`app__container ${sectionClass}`}>
			<SocialMedia />
			<div className='app__wrapper app__flex'>
				<Component />
				<div className='copyright gap-5'>
					<p className='mb-0 fs-10'>2024 Dev Aryan</p>
					<p className='mb-0 fs-10'>All rights reserved</p>
				</div>
			</div>
			<NavigationDots active={idName} />
		</div>
	)
}

export default AppWrap
