/** Component for Navigation dots */
import Tooltip from "Components/Tooltip"

const NavigationDots = ({ active }) => {

	return (
		<div className='app__navigation gap-15'>
			{['home', 'about', 'work', 'skills', 'certification', 'testimonials', 'contact'].map((item, index) => (
				<Tooltip key={item} content={item.charAt(0).toUpperCase() + item.slice(1)} placement="left">
					<a
						key={item + index}
						href={`#${item}`}
						aria-label={`Go to ${item}`}
						className={`app__navigation-dot bg-gradient  ${active === item ? "bg-primary" : "bg-dark-subtle"}`}
					/>
				</Tooltip>
			))}
		</div>
	)
}

export default NavigationDots